@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&family=Poppins:wght@200&display=swap');
#root {
  height: 100%;
}
body,
html {
  position: relative;
  height: 100%;
}
.bacImage {
  background-image: url("http://demo.colorlib.com/wp-content/uploads/sites/59/2016/03/photo-1443527216320-7e744084f5a7-1.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background-blend-mode: overlay;
  width: 100vw;
  height: 93vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.parallax {
  background-image: url("https://getwallpapers.com/wallpaper/full/b/e/7/74829.jpg");
  background-blend-mode: overlay;
  width: 100vw;
  height: 60vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-container {
  width: 100%;
  height: 70vh;
  background: url("https://getwallpapers.com/wallpaper/full/b/6/7/1382810-full-size-blue-ocean-background-2880x1800.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 19px !important;
  color: white !important;
}
.footerIcons > *{
    margin-right: 15px;
    font-size: 20px !important;

}
.footerIcons > *:hover{
    animation: bounce 1s infinite;

}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animationTimingFunction: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animationTimingFunction: cubic-bezier(0, 0, 0.2, 1);
  }
}
.font_change{
  font-family: 'Poppins', sans-serif;
}
.service_icon > *{
  margin-left: 20px;
  color: white !important;
}
